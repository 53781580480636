
import { defineComponent, ref, onMounted, provide, watch, reactive, computed, createVNode } from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { NavbarProps } from '@/API/types';
import Navbar from './components/NavigationBar.vue';
import WordingPreview from './Wording/WordingPreview.vue';
import {
  programTemplate,
  pgapiProgramPost,
  programProgramId,
  isWordingUploadAll,
  resetCombinability,
  storeCombinability
} from '@/API/program';
import { useStore } from 'vuex';
import { AutoTableCellProps, AutoTableColProps } from '@/types';
import { formatPgmCategory, formatRequestData, getCategory } from '@/utils';
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router';
import { budgetGenerate } from '@/API/program';
import useEventbus from '@/hooks/claim/useEventbus';
import { message, Modal } from 'ant-design-vue';
import { submitProgram } from '@/API/approve';
import { findbudgetExist } from '@/API/budget';
import { checkBudgetPeriod as checkBudgetPeriodAPI } from '@/API/program';
import useNavBar from '@/hooks/Program/useNavBar';
import DefaultCominabilityRuleModal from './CombinabilitySetting/components/DefaultCombinabilityRuleModal.vue';
import useBudgetCheck from '@/hooks/Program/useBudgetCheck';
import { cloneDeep } from 'lodash';

export default defineComponent({
  components: {
    Navbar,
    WordingPreview,
    DefaultCominabilityRuleModal
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isProgramDesign = computed(() => route.path === '/programdesign/design');
    const isCombinability = computed(() => route.path === '/programdesign/combinabilitysetting');

    const { customEmit } = useEventbus();

    const currentProgramId = computed(() => store.state.pgmData.programId);
    const currentProgramStatus = computed(() => store.state.pgmData.programStatusKey);
    const hasCurrentProgramSaved = computed(() => currentProgramStatus.value === 'Saved');

    // 导航的数据
    const { activeKey, navArr, changeNavStatus, getTabStatus, programAllStatus } = useNavBar();
    useBudgetCheck(programAllStatus);
    // 是否折叠显示
    const isToggle = ref(true);
    const togglePosition = () => {
      isToggle.value = !isToggle.value;
    };

    let originPgmData: any = {};

    const init = () => {
      // 需要清掉，不然会有上一个program的内容。那个请求两次的问题，再看看
      store.commit('updatePgmData', {});
      if (route.query?.pgmId) {
        return programProgramId({
          params: { programId: route.query.pgmId }
        }).then((res) => {
          // 更新整个Program大数据
          store.commit('updatePgmData', res);
          originPgmData = cloneDeep(res);
          // 因为category的选择放到了business UUIt的option里面了，选了哪个，才能找到对应的category
          getCategory();
          formatPgmCategory();
          store.dispatch('updateCustomerSelected');
          getTabStatus();
        });
      } else {
        return programTemplate().then((res) => {
          // res.customer = []
          store.commit('updatePgmData', res);
          // 因为category的选择放到了business UUIt的option里面了，选了哪个，才能找到对应的category
          getCategory();
          formatPgmCategory();
          store.dispatch('updateCustomerSelected');
          getTabStatus();
        });
      }
    };
    // 选择FA后。 判断对应F/A application date是否存在
    const checkFaDate = () => {
      if (
        (Object.keys(store.state.pgmData.offer).includes('fa') && store.state.pgmData.offer.fa) ||
        (Object.keys(store.state.pgmData.offer).includes('fa_buy_down') && store.state.pgmData.offer.fa_buy_down)
      ) {
        const periodArr = store.state.pgmData.eligibility.period.tableCell;
        const key = store.state.pgmData.eligibility.period.tableCellSelect.filter(
          (item: { display: string }) => item.display === 'F/A application date'
        )[0].id;
        let isCheckFaDate = false;
        let toDate = '';
        let fromDate = '';
        periodArr.forEach((item: any) => {
          if (item.ATTR.val === key && item.TO.val && item.FROM.val) {
            isCheckFaDate = true;
            toDate = item.TO.val;
            fromDate = item.FROM.val;
          }
        });
        if (isCheckFaDate && toDate && fromDate) {
          return true;
        } else {
          message.error('请选择F/A application date类型下的日期范围');
          return false;
        }
      } else {
        return true;
      }
    };
    const usingNewCode = ref<boolean>(false);
    // 保存通用program
    const saveAsync = async (resolve: Function, reject: Function, type = '') => {
      const data = await pgapiProgramPost(formatRequestData());
      if (type === 'FAoffer') {
        // 更新是否升级状态
        customEmit('updateupgrade');
      }
      const newCode = data.programCode;
      if (data.requireNewCode) {
        Modal.confirm({
          title: 'Note',
          content: data.programCode + ' program code 可用，是否更新项目编号?',
          onOk() {
            pgapiProgramPost(formatRequestData(), {
              params: { usingNewCode: true }
            })
              .then(() => {
                message.success('Save successfully!');
                if (!route.query.pgmId) {
                  router.push({
                    path: '/programdesign/design',
                    query: {
                      pgmId: store.state.pgmData.programId
                    }
                  });
                  setTimeout(() => {
                    if (type === 'FAoffer') {
                      init().then(() => {
                        resolve('success');
                      });
                    } else {
                      resolve('success');
                      getTabStatus();
                      window.location.reload();
                    }
                  }, 1);
                } else {
                  (type ? Promise.resolve() : init()).then(() => {
                    getTabStatus();
                    resolve('success');
                  });
                }
              })
              .catch((e) => {
                reject(e);
              });
          },
          cancelText: 'No',
          onCancel() {
            pgapiProgramPost(formatRequestData(), {
              params: { usingNewCode: false }
            })
              .then(() => {
                message.success('Save successfully!');
                getTabStatus();
                resolve('success');
              })
              .catch((e) => {
                reject(e);
              });
          }
        });
      } else {
        message.success('Save successfully!');
        if (!route.query.pgmId) {
          router.push({
            path: '/programdesign/design',
            query: { pgmId: store.state.pgmData.programId }
          });
          setTimeout(() => {
            if (type === 'FAoffer') {
              init().then(() => {
                resolve('success');
              });
            } else {
              resolve('success');
              getTabStatus();
              window.location.reload();
            }
          }, 1);
        } else {
          (type ? Promise.resolve() : init()).then(() => {
            getTabStatus();
            resolve('success');
          });
        }
      }
    };

    const saveFAdata = async (resolve: Function, reject: Function) => {
      const data = await pgapiProgramPost(formatRequestData());
      if (data.requireNewCode) {
        Modal.confirm({
          title: 'Note',
          content: data.programCode + ' program code 可用，是否更新项目编号?',
          onOk() {
            pgapiProgramPost(formatRequestData(), {
              params: { usingNewCode: true }
            })
              .then(() => {
                // 更新是否升级状态
                customEmit('updateupgrade');
                message.success('Save successfully!');
                if (!route.query.pgmId) {
                  router.push({
                    path: '/programdesign/design',
                    query: {
                      pgmId: store.state.pgmData.programId
                    }
                  });
                  setTimeout(() => {
                    init();
                  }, 1);
                } else {
                  init();
                }
                resolve('success');
              })
              .catch((e) => {
                reject(e);
              });
          },
          cancelText: 'No',
          onCancel() {
            pgapiProgramPost(formatRequestData(), {
              params: { usingNewCode: false }
            })
              .then(() => {
                message.success('Save successfully!');
                resolve('success');
              })
              .catch((e) => {
                reject(e);
              });
          }
        });
      } else {
        message.success('Save successfully!');
        resolve('success');
        if (!route.query.pgmId) {
          router.push({
            path: '/programdesign/design',
            query: { pgmId: store.state.pgmData.programId }
          });
          // 更新code
          setTimeout(() => {
            init();
          }, 1);
        } else {
          init();
        }
      }
    };

    // 验证Measure没有选择
    const validationMeasure = (): boolean => {
      if (Object.keys(store.state.pgmData.offer).length > 0) {
        for (const key in store.state.pgmData.offer) {
          if (store.state.pgmData.offer[key].incentive.length > 0) {
            for (const item of store.state.pgmData.offer[key].incentive) {
              // 如果incentive有空组时, 要跳过
              if (item.groupTable !== undefined) {
                for (const val of item.groupTable) {
                  if (val.tableCol.some((col: AutoTableColProps) => col.id.indexOf('Undefined') > -1)) {
                    return true;
                  }
                }
              }
            }
          }
          if (store.state.pgmData.offer[key].precondition.length > 0) {
            for (const item of store.state.pgmData.offer[key].precondition) {
              if (item.tableCol.some((col: AutoTableColProps) => col.id.indexOf('Undefined') > -1)) {
                return true;
              }
            }
          }
          if (store.state.pgmData.offer[key].vehicleLicense.length > 0) {
            for (const item of store.state.pgmData.offer[key].vehicleLicense) {
              if (item.tableCol.some((col: AutoTableColProps) => col.id.indexOf('Undefined') > -1)) {
                return true;
              }
            }
          }
        }
      }
      return false;
    };
    // 校验Insurance total support amount自定义标题
    const validationInsuranceMeasureTitles = (): boolean => {
      if (!Object.keys(store.state.pgmData.offer).length!) {
        return true;
      }
      for (const key in store.state.pgmData.offer) {
        if (!store.state.pgmData.offer[key].incentive.length) {
          continue;
        }
        for (const item of store.state.pgmData.offer[key].incentive) {
          if (item.groupTable === undefined) {
            continue;
          }
          for (const val of item.groupTable) {
            const titles = new Set<string>();
            for (const col of val.tableCol) {
              if (col.group === 'Insurance total support amount') {
                if (!col.display) return false;
                if (!titles.has(col.display)) {
                  titles.add(col.display);
                } else {
                  return false;
                }
              }
            }
          }
        }
      }
      return true;
    };
    // 验证from, to不能交叉
    const validationFromTo = (): boolean => {
      if (Object.keys(store.state.pgmData.offer).length > 0) {
        for (const key in store.state.pgmData.offer) {
          if (store.state.pgmData.offer[key].precondition.length > 0) {
            for (const item of store.state.pgmData.offer[key].precondition) {
              for (let coli = 0; coli < item.tableCol.length; coli++) {
                let flagFrom = 0;
                if (item.tableCol[coli].id.indexOf('-to') > -1) {
                  for (let celli = 0; celli < item.tableCell.length; celli++) {
                    if (
                      item.tableCell[celli][item.tableCol[coli].id].val &&
                      item.tableCell[celli][item.tableCol[coli].id].val <= flagFrom
                    ) {
                      return true;
                    } else {
                      flagFrom = item.tableCell[celli][item.tableCol[coli].id].val;
                    }
                  }
                }
              }
            }
          }
        }
      }
      return false;
    };

    const checkProgramDataChangeEffectComb = () => {
      let changedFlag = false;

      const newPgmData = store.state.pgmData;
      // console.log('pgmData origin new', originPgmData, newPgmData);
      // * 1. Offer Type 判断
      const originOffer = originPgmData.offer;
      const newOffer = newPgmData.offer;
      const originOfferKeys = Object.keys(originOffer);
      const newOfferKeys = Object.keys(newOffer);
      if (originOfferKeys.length !== newOfferKeys.length) {
        // offer 长度不同
        changedFlag = true;
      } else {
        // 长度相同 但是内容不同
        changedFlag = newOfferKeys.some((key: string) => !originOfferKeys.includes(key));
      }

      // * 2. Eligible Period：增行，删行，（Attribute，From，To）的值
      if (changedFlag) return true;
      const originPeriod = originPgmData.eligibility.period.tableCell;
      const newPeriod = newPgmData.eligibility.period.tableCell.filter((item: any) => {
        return item.ATTR.val !== '' || item.FROM.val !== '' || item.TO.val !== '';
      });
      if (originPeriod.length !== newPeriod.length) {
        // period 长度不同
        changedFlag = true;
      } else {
        // 长度相同 但是 attr from to 的内容不同
        const originPeriodAttrFromToValue = originPeriod.map((item: any) => {
          return `${item.ATTR.val} ${item.FROM.val} ${item.TO.val}`;
        });
        const newPeriodAttrFromToValue = newPeriod.map((item: any) => {
          return `${item.ATTR.val} ${item.FROM.val} ${item.TO.val}`;
        });
        for (let i = 0; i < originPeriodAttrFromToValue.length; i++) {
          const originValue = originPeriodAttrFromToValue[i];
          const newValue = newPeriodAttrFromToValue[i];
          if (originValue !== newValue) {
            changedFlag = true;
            break;
          }
        }
      }

      // * 3. Eligible Vehicle：增加Group，删Group，新增行 删除行（Brand， Make，Type Class）的值
      if (changedFlag) return true;
      const originVehicle = originPgmData.eligibility.vehicle;
      const newVehicle = newPgmData.eligibility.vehicle;
      // 判断 group 长度是否相等
      if (originVehicle.length !== newVehicle.length) {
        // vehicle group 长度不同
        changedFlag = true;
      } else {
        for (let i = 0; i < originVehicle.length; i++) {
          const originVehicleGroup = originVehicle[i].tableCell;
          // 过滤掉空行
          const newVehicleGroup = newVehicle[i].tableCell.filter((item: any) => {
            return item['Vehicle.brand'].val.length !== 0;
          });
          if (originVehicleGroup.length !== newVehicleGroup.length) {
            // 每个 vehicle group 中的表格数据 长度不同
            changedFlag = true;
            break;
          } else {
            // 长度相同 但是 brand make typeclass 的内容不同
            const originVehicleGroupBrandMakeTypeClassValue = originVehicleGroup.map((item: any) => {
              return `${item['Vehicle.brand'].val.join(' ')} ${item['Vehicle.make'].val.join(' ')} ${item[
                'Vehicle.typeClass'
              ].val.join(' ')}`;
            });
            const newVehicleGroupBrandMakeTypeClassValue = newVehicleGroup.map((item: any) => {
              return `${item['Vehicle.brand'].val.join(' ')} ${item['Vehicle.make'].val.join(' ')} ${item[
                'Vehicle.typeClass'
              ].val.join(' ')}`;
            });

            for (let j = 0; j < originVehicleGroup.length; j++) {
              const originVehicleGroupValue = originVehicleGroupBrandMakeTypeClassValue[j];
              const newVehicleGroupValue = newVehicleGroupBrandMakeTypeClassValue[j];
              if (originVehicleGroupValue !== newVehicleGroupValue) {
                changedFlag = true;
                break;
              }
              const originVehicleGroupChildren = originVehicleGroup[j].children;
              const newVehicleGroupChildren = newVehicleGroup[j].children;
              if (originVehicleGroupChildren.length !== newVehicleGroupChildren.length) {
                // 每个 vehicle group 中的表格数据 长度不同
                changedFlag = true;
                break;
              } else {
                // 长度相同 但是 brand make typeclass 的内容不同
                const originVehicleGroupChildrenBrandMakeTypeClassValue = originVehicleGroupChildren.map(
                  (item: any) => {
                    return `${item['Vehicle.brand'].val.join(' ')} ${item['Vehicle.make'].val.join(' ')} ${item[
                      'Vehicle.typeClass'
                    ].val.join(' ')}`;
                  }
                );
                const newVehicleGroupChildrenBrandMakeTypeClassValue = newVehicleGroupChildren.map((item: any) => {
                  return `${item['Vehicle.brand'].val.join(' ')} ${item['Vehicle.make'].val.join(' ')} ${item[
                    'Vehicle.typeClass'
                  ].val.join(' ')}`;
                });
                for (let k = 0; k < originVehicleGroupChildrenBrandMakeTypeClassValue.length; k++) {
                  const originVehicleGroupChildrenValue = originVehicleGroupChildrenBrandMakeTypeClassValue[k];
                  const newVehicleGroupChildrenValue = newVehicleGroupChildrenBrandMakeTypeClassValue[k];
                  if (originVehicleGroupChildrenValue !== newVehicleGroupChildrenValue) {
                    changedFlag = true;
                    break;
                  }
                }
              }
              if (changedFlag) break;
            }
            if (changedFlag) break;
          }
        }
      }

      // * 4. Customer: Customer Type的值
      if (changedFlag) return true;
      const originCustomer = originPgmData.customer;
      const newCustomer = newPgmData.customer;
      if (originCustomer.length !== newCustomer.length) {
        // customer group 长度不同
        changedFlag = true;
      } else {
        for (let i = 0; i < originCustomer.length; i++) {
          const originCustomerGroup = originCustomer[i].tableCell;
          // 过滤掉空行
          const newCustomerGroup = newCustomer[i].tableCell.filter((item: any) => {
            return (
              item['Customer.list'].val !== '' ||
              item['Customer.standardContent'].val !== '' ||
              item['Customer.type'].val !== ''
            );
          });
          if (originCustomerGroup.length !== newCustomerGroup.length) {
            // 每一个 customer group 的表格行数 长度不同
            changedFlag = true;
            break;
          } else {
            for (let j = 0; j < originCustomerGroup.length; j++) {
              const originCustomerGroupCustomerType = originCustomerGroup[j]['Customer.type'].val;
              const newCustomerGroupCustomerType = newCustomerGroup[j]['Customer.type'].val;
              if (originCustomerGroupCustomerType !== newCustomerGroupCustomerType) {
                changedFlag = true;
                break;
              }
            }
            if (changedFlag) break;
          }
        }
      }

      // * 5. (根据offer来的，可能有多个) Incentive/Unit: 增行，删行，增列，删列，（Brand, Make, Type Class)的值
      if (changedFlag) return true;
      // 遍历所选offer
      for (let i = 0; i < originOfferKeys.length; i++) {
        const originIncentive = originOffer[originOfferKeys[i]].incentive;
        const newIncentive = newOffer[newOfferKeys[i]].incentive;
        if (originIncentive.length !== newIncentive.length) {
          // incentive group 数量不同
          changedFlag = true;
          break;
        } else {
          for (let j = 0; j < originIncentive.length; j++) {
            const originIncentiveGroup = originIncentive[j]?.groupTable?.[0]; //? groupTable 什么情况下会有多个？
            const newIncentiveGroup = newIncentive[j]?.groupTable?.[0]; //? groupTable 什么情况下会有多个？
            if (originIncentiveGroup === undefined && newIncentiveGroup === undefined) break;
            if (originIncentiveGroup === undefined && newIncentiveGroup !== undefined) {
              changedFlag = true;
              break;
            }
            if (originIncentiveGroup !== undefined && newIncentiveGroup === undefined) {
              changedFlag = true;
              break;
            }

            const originIncentiveGroupTableCol = originIncentiveGroup.tableCol;
            const originIncentiveGroupTableCell = originIncentiveGroup.tableCell;
            const newIncentiveGroupTableCol = newIncentiveGroup.tableCol;
            // ? 除去最后一行默认添加的空行 可能会出问题，需要考虑新的方式
            let newIncentiveGroupTableCell = [];
            // offer 是 sssc 的情况不需要处理
            if (originOfferKeys[i] === 'sssc') {
              newIncentiveGroupTableCell = newIncentiveGroup.tableCell;
            } else {
              newIncentiveGroupTableCell = newIncentiveGroup.tableCell.slice(0, newIncentiveGroup.tableCell.length - 1);
            }

            // 判断列
            if (originIncentiveGroupTableCol.length !== newIncentiveGroupTableCol.length) {
              // 判断列数是否相同
              changedFlag = true;
              break;
            } else {
              // 列数相同，判断列名、顺序是否相同
              const originTableCol = originIncentiveGroupTableCol.map((item: any) => item.id);
              const newTableCol = newIncentiveGroupTableCol.map((item: any) => item.id);
              for (let k = 0; k < originTableCol.length; k++) {
                const originTableColId = originTableCol[k];
                const newTableColId = newTableCol[k];
                if (originTableColId !== newTableColId) {
                  changedFlag = true;
                  break;
                }
              }
            }
            // 判断行
            if (originIncentiveGroupTableCell.length !== newIncentiveGroupTableCell.length) {
              // 行数是否相同
              changedFlag = true;
              break;
            } else {
              // 长度相同 但是 brand make typeclass 的内容不同
              const originIncentiveGroupBrandMakeTypeClassValue = originIncentiveGroupTableCell.map((item: any) => {
                return `${item['Vehicle.brand']?.val?.join(' ')} ${item['Vehicle.make']?.val?.join(' ')} ${item[
                  'Vehicle.typeClass'
                ]?.val?.join(' ')}`;
              });
              const newIncentiveGroupBrandMakeTypeClassValue = newIncentiveGroupTableCell.map((item: any) => {
                return `${item['Vehicle.brand']?.val?.join(' ')} ${item['Vehicle.make']?.val?.join(' ')} ${item[
                  'Vehicle.typeClass'
                ]?.val?.join(' ')}`;
              });

              for (let k = 0; k < originIncentiveGroupTableCell.length; k++) {
                const originIncentiveGroupValue = originIncentiveGroupBrandMakeTypeClassValue[k];
                const newIncentiveGroupValue = newIncentiveGroupBrandMakeTypeClassValue[k];
                if (originIncentiveGroupValue !== newIncentiveGroupValue) {
                  changedFlag = true;
                  break;
                }
                const originIncentiveGroupChildren = originIncentiveGroupTableCell[k].children;
                const newIncentiveGroupChildren = newIncentiveGroupTableCell[k].children;
                if (originIncentiveGroupChildren && newIncentiveGroupChildren) {
                  if (originIncentiveGroupChildren.length !== newIncentiveGroupChildren.length) {
                    // 每个 vehicle group 中的表格数据 长度不同
                    changedFlag = true;
                    break;
                  } else {
                    //children 长度相同 但是 brand make typeclass 的内容不同
                    const originIncentiveGroupChildrenBrandMakeTypeClassValue = originIncentiveGroupChildren.map(
                      (item: any) => {
                        return `${item['Vehicle.brand']?.val?.join(' ')} ${item['Vehicle.make']?.val?.join(' ')} ${item[
                          'Vehicle.typeClass'
                        ]?.val?.join(' ')}`;
                      }
                    );
                    const newIncentiveGroupChildrenBrandMakeTypeClassValue = newIncentiveGroupChildren.map(
                      (item: any) => {
                        return `${item['Vehicle.brand']?.val?.join(' ')} ${item['Vehicle.make']?.val?.join(' ')} ${item[
                          'Vehicle.typeClass'
                        ]?.val?.join(' ')}`;
                      }
                    );
                    if (originIncentiveGroupChildrenBrandMakeTypeClassValue) {
                      for (let m = 0; m < originIncentiveGroupChildrenBrandMakeTypeClassValue.length; m++) {
                        const originIncentiveGroupChildrenValue = originIncentiveGroupChildrenBrandMakeTypeClassValue[m];
                        const newIncentiveGroupChildrenValue = newIncentiveGroupChildrenBrandMakeTypeClassValue[m];
                        if (originIncentiveGroupChildrenValue !== newIncentiveGroupChildrenValue) {
                          changedFlag = true;
                          break;
                        }
                      }
                    }
                  }
                } else if (!(!originIncentiveGroupChildren && !newIncentiveGroupChildren)) {
                  changedFlag = true;
                  break;
                }
                if (changedFlag) break;
              }
              if (changedFlag) break;
            }
          }
          if (changedFlag) break;
        }
      }

      console.log('changedFlag', changedFlag);
      return changedFlag;
    };

    const handleSaveProgram = () => {
      // 项目未保存状态，直接保存
      if (!hasCurrentProgramSaved.value) {
        saveProgram();
        return;
      }
      // 针对Program Status为Saved的项目，当Combinability Status & Parameter Status均为Draft时，designer在Design页面点击Save按钮，不弹出提示。
      if (programAllStatus.value.combinability === 'Draft' && programAllStatus.value.parameter === 'Draft') {
        saveProgram();
        return;
      }
      // 当Combinability Status 或 Parameter Status不为Draft时，desigenr在Deisgn页面点击Save按钮，需校验
      if (checkProgramDataChangeEffectComb()) {
        Modal.confirm({
          title: 'Tips',
          icon: createVNode(ExclamationCircleOutlined),
          content:
            'Your current change points may affect the combinability/parameter that has been saved, please check!',
          okText: 'OK',
          cancelText: 'Cancel',
          onOk: () => {
            saveProgram();
          }
        });
      } else {
        saveProgram();
      }
    };

    // 监听，更新组件数据到父组件
    const saveProgram: Function = (): Promise<string> => {
      return new Promise((resolve, reject) => {
        // 项目名称
        if (!store.state.pgmData.programNameCN) {
          message.error('项目名称(中文)不能为空!');
          return;
        }
        // 项目英文名
        if (!store.state.pgmData.programName) {
          message.error('Please input Program Name!');
          return;
        }
        customEmit('updateperiod');
        // period有没有报红的
        if (
          store.state.pgmData.eligibility.period.tableCell.some((item: AutoTableCellProps) => item.bgcolor === true)
        ) {
          message.error('Please amend period!');
          return;
        }

        // Budget period 必填
        customEmit('updateBudgetPeriod');
        if (
          store.state.pgmData.budgetShowFlag &&
          (!store.state.pgmData.budgetPeriodFrom || !store.state.pgmData.budgetPeriodTo)
        ) {
          message.error('Please amend Budget Period!');
          return;
        }
        //
        customEmit('updatedealer');
        // Dealer一定要选择一个
        if (
          store.state.pgmData.eligibility.dealer.tableCell.every(
            (item: AutoTableCellProps) => item['Dealer.dealerType'].val.length === 0
          )
        ) {
          message.error('Please amend Dealer!');
          return;
        }
        // 验证from, to不能交叉
        // if (validationFromTo()) {
        //     message.error('From or To is Error!')
        //     return
        // }
        // 判断Measure列表头有没有选择
        if (validationMeasure()) {
          message.error('Please select the Measure');
          return;
        }
        if (!validationInsuranceMeasureTitles()) {
          return;
        }
        customEmit('updatevehicle');
        customEmit('updatecalculator');
        customEmit('updatecustomer');
        customEmit('updatequota');
        customEmit('updateattachement');
        if (!checkFaDate()) {
          return;
        } else {
          customEmit('updatefa');
        }
        const isCheckoutFa = Object.keys(store.state.pgmData.offer).includes('fa');
        const isCheckoutFaBuyDown = Object.keys(store.state.pgmData.offer).includes('fa_buy_down');
        if (isCheckoutFa || isCheckoutFaBuyDown) {
          setTimeout(() => {
            saveAsync(resolve, reject, 'FAoffer');
          }, 1000);
        } else {
          setTimeout(() => {
            saveAsync(resolve, reject);
          }, 1000);
        }
      });
    };

    // 注入方法 子组件使用
    provide('updataprogram', saveProgram);

    // 滚动条
    const scrollTop = ref();
    // 全局监听滚动条
    const handleScroll = () => {
      scrollTop.value = document.documentElement.scrollTop;
    };
    // 悬浮导航
    const handleAnchorClick = (e: MouseEvent, link: { title: string; href: string }) => {
      // 阻止点击的默认事件修改路由
      e.preventDefault();
      const srcolls = document.getElementById(link.href) as HTMLElement;
      // 滚动时
      srcolls.scrollIntoView(true);
    };

    const handleParameter = () => {
      router.push({
        path: '/parametersetup',
        query: {
          pgmId: store.state.pgmData.programId,
          type: 'button'
        }
      });
    };

    // preview
    const visiblePreview = ref(false);
    const handlePreview = () => {
      sessionStorage.setItem('previewPgmId', store.state.pgmData.programId);
      visiblePreview.value = true;
    };

    const handleEditWording = () => {
      window.open(`${window.location.origin}/#/wordingedit`);
    };
    // wording对比
    const handleCompareWording = () => {
      window.open(`${window.location.origin}/#/wordingcompare`);
    };

    const handleSubmit = () => {
      const params = {
        program: [{ id: store.state.pgmData.programId }]
      };
      Modal.confirm({
        title: '提示',
        content: 'Submit for Controlling Approve?',
        onOk() {
          isWordingUploadAll({ programId: store.state.pgmData.programId })
            .then((res) => {
              if (res) {
                return saveProgram();
              } else {
                message.error('Submit failed. Please upload updated wording for internal/external wording');
                return Promise.reject();
              }
            })
            .then(() => {
              return submitProgram(params);
            })
            .then(() => {
              message.success('Submit successfully!');
            });
        },
        onCancel() {
          console.log('Cancel');
        }
      });
    };
    onMounted(() => {
      init();
      // 全局监听scroll-top
      window.addEventListener('scroll', handleScroll);
    });

    const defaultCominabilityRuleModalVisible = ref(false);
    const displayDefaultCombinabilityRule = () => {
      defaultCominabilityRuleModalVisible.value = true;
    };

    const handleResetCombinability = () => {
      Modal.confirm({
        title: 'Are you sure you want to reset program combinability to default value?',
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          resetCombinability({ programId: currentProgramId.value }).then((res: any) => {
            store.commit('updatePgmCombinability', res);
            store.commit('updateCombinabilityResetKey');
            message.success('Reset successfully!');
          });
        }
      });
    };

    const saveCombinabilitySetting = () => {
      storeCombinability(store.state.pgmCombinability).then(() => {
        getTabStatus();
        message.success('Save successfully!');
      });
    };
    return {
      navArr,
      scrollTop,
      handleAnchorClick,
      saveProgram,
      isToggle,
      togglePosition,
      handleParameter,
      handlePreview,
      visiblePreview,
      handleSubmit,
      handleEditWording,
      handleCompareWording,
      activeKey,
      handleSaveProgram,
      isProgramDesign,
      isCombinability,
      hasCurrentProgramSaved,
      defaultCominabilityRuleModalVisible,
      displayDefaultCombinabilityRule,
      handleResetCombinability,
      saveCombinabilitySetting
    };
  }
});
